import { useEffect } from "react";
import { useRouter } from "next/router";
import { availableLanguageConst } from "@/app/const/availableLanguageConst";
import { getLanguageFromNavigatorHelper } from "@/app/helpers/getLanguageFromNavigatorHelper";

const Home = () => {

    const router = useRouter()

    useEffect(() => {
        const detectedLangage = getLanguageFromNavigatorHelper(navigator.language)
        if ( availableLanguageConst.some((item)=> item.code === detectedLangage)){
            router.push("/"+detectedLangage).then()
        }else{
            router.push("/en").then()
        }
    }, []);

}

export default Home
